import React, { useState, useEffect } from "react";
import "./FilterComponent.css";
import axios from "axios";

const FilterComponent = ({ selectedFilters, setSelectedFilters, mass, height, width }) => {
  const [dropData, setDropData] = useState({});

  const loadDropdowns = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/auth/v1/dropdowns`
      );
      const dropdownData = response.data;
      setDropData(dropdownData);
    } catch (error) {
      console.error("Error loading dropdowns:", error);
      throw error;
    }
  };

  useEffect(() => {
    loadDropdowns();
  }, []);

  const handleCheckboxChange = (filterCategory, value) => {
    setSelectedFilters((prevFilters) => {
      const updatedCategory = prevFilters[filterCategory].includes(value)
        ? prevFilters[filterCategory].filter((item) => item !== value)
        : [...prevFilters[filterCategory], value];

      return {
        ...prevFilters,
        [filterCategory]: updatedCategory,
      };
    });
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      setSelectedFilters({
        shape: [],
        color: [],
        vendor: [],
        teethCase: [],
        width: "",
        height: "",
        mass: ""
      });
    }
  };

  // Handlers for mass, width, and height changes
  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  return (
    <div className="filter-container">
      <div className="filter-section">
        {/* 'All' button to clear all filters */}
        <button onClick={handleSelectAll}>Clear all filters</button>

        {/* Filters for Size */}
        <h3>Filter by Size</h3>
        <div className="side">
          <input
            type="number"
            placeholder="Mass"
            value={selectedFilters.mass || ""}
            onChange={(e) => handleInputChange(e, "mass")}
          />
          <div className="wh">
            <input
              type="number"
              placeholder="Width"
              value={selectedFilters.width || ""}
              onChange={(e) => handleInputChange(e, "width")}
            />
            <input
              type="number"
              placeholder="Height"
              value={selectedFilters.height || ""}
              onChange={(e) => handleInputChange(e, "height")}
            />
          </div>
        </div>

        {/* Filters for shape */}
        <h3>Filter by shape</h3>
        {dropData?.Shapes?.map((m) => (
          <label key={m.name}>
            <input
              type="checkbox"
              checked={selectedFilters.shape?.includes(m.name)}
              onChange={() => handleCheckboxChange("shape", m.name)}
            />
            {m.name}
          </label>
        ))}
      </div>

      <div className="filter-section">
        <h3>Filter by color</h3>
        {dropData?.Colors?.map((m) => (
          <label key={m.name}>
            <input
              type="checkbox"
              checked={selectedFilters.color?.includes(m.name)}
              onChange={() => handleCheckboxChange("color", m.name)}
            />
            {m.name}
          </label>
        ))}
      </div>

      <div className="filter-section">
        <h3>Filter by vendor</h3>
        {dropData?.Vendors?.map((m) => (
          <label key={m.name}>
            <input
              type="checkbox"
              checked={selectedFilters.vendor?.includes(m.name)}
              onChange={() => handleCheckboxChange("vendor", m.name)}
            />
            {m.name}
          </label>
        ))}
      </div>

      <div className="filter-section">
        <h3>Filter by case</h3>
        {dropData?.Cases?.map((m) => (
          <label key={m.name}>
            <input
              type="checkbox"
              checked={selectedFilters.teethCase?.includes(m.name)}
              onChange={() => handleCheckboxChange("teethCase", m.name)}
            />
            {m.name}
          </label>
        ))}
      </div>
    </div>
  );
};

export default FilterComponent;
