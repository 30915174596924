import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./MiddleSection.css";
import "../../assets/css/mobile.css";
import People from "../../assets/images/peoples.png";
import Users from "../../assets/images/users.png";
import Line from "../../assets/images/line.png";
import { Select } from "antd";

const { Option } = Select;

const MiddleSection = () => {
  const [dropData, setDropData] = useState({});
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [mass, setMass] = useState("");
  const [color, setColor] = useState([]);
  const [shape, setShape] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [teethCase, setTeethCase] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("userToken");

  const loadDropdowns = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/auth/v1/dropdowns`
      );
      const dropdownData = response.data;
      setDropData(dropdownData);
      console.log("Dropdown data loaded:", dropdownData);
    } catch (error) {
      console.error("Error loading dropdowns:", error);
      throw error;
    }
  };

  useEffect(() => {
    loadDropdowns();
  }, []);

  const handleSearch = async () => {
    if (token) {
      const selectedFilters = {
        width: width || undefined,
        height: height || undefined,
        mass: mass || undefined,
        color: color.length > 0 ? JSON.stringify(color) : undefined,
        shape: shape.length > 0 ? JSON.stringify(shape) : undefined,
        vendor: vendor.length > 0 ? JSON.stringify(vendor) : undefined,
        teethCase: teethCase.length > 0 ? JSON.stringify(teethCase) : undefined,
      };

      const queryParams = new URLSearchParams();

      for (const [key, value] of Object.entries(selectedFilters)) {
        if (value !== undefined) {
          queryParams.append(key, value);
        }
      }

      navigate(`/product-results?${queryParams.toString()}`);
    } else {
      navigate(`/`);
    }
  };

  // Function to check if any filters are selected
  const isAnyFilterSelected = () => {
    return (
      mass ||
      width ||
      height ||
      color.length > 0 ||
      shape.length > 0 ||
      vendor.length > 0 ||
      teethCase.length > 0
    );
  };

  return (
    <div className="container">
      <div className="line">
        <img src={Line} />
      </div>
      <div className="cd-container">
        <div className="cd-left">
          <h2>Discover the Best Fit, Driven by AI Intelligence</h2>
          <img src={People} />
        </div>
        <div className="cd-right">
          <div className="smile-search-container">
            <svg
              width="174"
              height="104"
              viewBox="0 0 174 104"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="search-svg"
            >
              <path
                d="M20.7725 -107L21.5461 -91.4372C27.8117 34.5984 156.396 116.91 273.477 69.834V69.834"
                stroke="#70A7FC"
                strokeWidth="40"
                strokeLinecap="round"
              />
            </svg>
            <span className="secondary-container">
              <div className="header">
                <p>Discover best teeth.</p>
              </div>
              <h1>Find best smile</h1>
              <div className="teeth-size teeth-weight">
                <label className="input-label">Tooth size</label>
                <div className="input-group-2">
                  <input
                    type="text"
                    placeholder="0.00"
                    className="custom-box"
                    value={mass}
                    onChange={(e) => setMass(e.target.value)}
                  />
                </div>
              </div>

              <div className="teeth-size">
                <div className="input-group-2">
                  <input
                    type="text"
                    placeholder="Width"
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Height"
                    className="custom-box"
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                  />
                </div>
              </div>
              <div className="filters">
                <label className="input-label">Filters</label>
                <div className="filter-group">
                  <Select
                    mode="multiple"
                    value={color.length > 0 ? color : []}
                    onChange={(value) => setColor(value)}
                    placeholder="Select Color"
                    style={{ width: "100%" }}
                    className="multi-select"
                  >
                    {dropData?.Colors?.map((m) => (
                      <Option key={m?.name} value={m?.name}>
                        {m?.name}
                      </Option>
                    ))}
                  </Select>

                  <Select
                    mode="multiple"
                    value={shape}
                    onChange={(value) => setShape(value)}
                    placeholder="Select Shape"
                    style={{ width: "100%" }}
                  >
                    {dropData?.Shapes?.map((m) => (
                      <Option key={m?.name} value={m?.name}>
                        {m?.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="filter-group">
                  <Select
                    mode="multiple"
                    value={vendor}
                    onChange={(value) => setVendor(value)}
                    placeholder="Select Manufacturer"
                    style={{ width: "100%" }}
                  >
                    {dropData?.Vendors?.map((m) => (
                      <Option key={m?.name} value={m?.name}>
                        {m?.name}
                      </Option>
                    ))}
                  </Select>

                  <Select
                    mode="multiple"
                    value={teethCase}
                    onChange={(value) => setTeethCase(value)}
                    placeholder="Select Case"
                    style={{ width: "100%" }}
                  >
                    {dropData?.Cases?.map((m) => (
                      <Option key={m?.name} value={m?.name}>
                        {m?.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <button
                className="search-btn"
                onClick={handleSearch}
                disabled={!isAnyFilterSelected()} 
              >
                Search
              </button>
            </span>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="users">
          <img src={Users} alt="users" />
        </div>
      </div>

      <div className="section">
        <div className="auto-slider">
          <h2>Our Partners</h2>
        </div>
      </div>
    </div>
  );
};

export default MiddleSection;
