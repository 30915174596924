import React, { useState, useEffect } from 'react';
import "./Navbar.css";
import companylogo from "../../assets/images/logo.png";
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 758);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem('userToken');
  const { enqueueSnackbar } = useSnackbar();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 758);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const goToAuthPage = (mode) => {
    navigate(`/user-auth?mode=${mode}`);
  };

  const handleBack = () => {
    navigate(`/home`);
  };

  const handleLogout = () => {
    localStorage.removeItem('userToken');
   
    navigate('/'); 
    enqueueSnackbar('Logout successfull!', { 
      variant: 'success', 
    });
  };



  if (isMobile) {
    return (
      <div className='navbar-container'>
        <div className='company-logo-container'>
          <img src={companylogo} alt="company-logo" />
        </div>
        <div className={`hamburger-menu ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          &#9776; {/* Hamburger icon */}
        </div>
        <div className={`links-container ${isOpen ? 'open' : ''}`}>
          <a href="">About us</a>
          <a href="">Contact us</a>
          <div className={`auth-buttons-container ${isOpen ? 'open' : ''}`}>
            {isLoggedIn ? (
              <button onClick={handleLogout}>Logout</button>
            ) : (
              <>
                <button onClick={() => goToAuthPage('login')}>Login</button>
                <button onClick={() => goToAuthPage('signup')}>Signup</button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="navbar-container">
      <div className="links-container">
        <a href="">About us</a>
        <a href="">Contact us</a>
      </div>
      <div className="company-logo-container" onClick={handleBack}>
        <img src={companylogo} alt="company-logo" />
      </div>
      <div className="auth-buttons-container">
        {isLoggedIn ? (<>
          {/* <button className='profile-icon-container' onClick={() => goToUserDetailsPAge()}>
          <FaRegUserCircle className='profile-icon'/>
          </button> */}
          <button onClick={handleLogout}>Logout</button>
          </>
        ) : (
          <>
            <button onClick={() => goToAuthPage('login')}>Login</button>
            <button onClick={() => goToAuthPage('signup')}>Signup</button>
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;
