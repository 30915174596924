import React from "react";
import { useNavigate } from "react-router-dom";
import "./ResultCards.css";
import "../../assets/css/mobile.css";

const ResultCards = ({
  _id,
  vendor,
  title,
  description,
  color,
  width,
  height,
  createdDate,
  shape,
  mass,
  images,
  casetype,
}) => {
  return (
    <div
      className="result-card"
      onClick={() => window.location.href = `/product-description/${_id}`}
    >
      <div className="results-top-section">
        <p>
          <p>W: {width}</p>
          <p>H: {height}</p>
        </p>
        <p>{vendor}</p>
      </div>

      <div className="card-img">
        <img src={images} />
      </div>

      <div className="description-section">
        <div className="description-section-text">
          <p>{title}</p>
          <p>{description}</p>
        </div>

        <div className="color-box-primary">
          <div
            className="color-box-secondary"
            style={{ backgroundColor: color }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ResultCards;
