import React from 'react';
import './Loader.css'; // Make sure to create this CSS file
import companylogo from "../../assets/images/image.jpg";

const Loader = () => {
  return (
    <div className="loader-container">
      {/* Image */}
      <img
        src={companylogo} // Replace with your image source
        alt="Loading"
        className="loader-image"
      />
      
      {/* Loading bar */}
      <div className="loading-bar">
        <div className="loading-progress"></div>
      </div>
    </div>
  );
};

export default Loader;
