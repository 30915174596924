import React, { useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/HomePage/Home';
import ProductDescriptionPage from './pages/ProductDescriptionPage/ProductDescriptionPage';
import ResultsPage from './pages/ResultsPage/ResultsPage';
import Navbar from './components/Navbar/Navbar';
import UserDetails from './pages/UserDetails/UserDetails';
import Loader from './components/Loader/Loader';
import Login from './pages/AuthPage/Login';
import './App.css'
import Signup from './pages/AuthPage/Signup';



function App() {
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  return (

    <SnackbarProvider maxSnack={3}>
    <Router>
   
      <Routes>
        <Route path="/home" element={<><Navbar/><Home/></>} />
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/product-description/:id" element={<><Navbar/><ProductDescriptionPage/></>} />
        <Route path="/product-results" element={<><Navbar/><ResultsPage/></>} />
        <Route path="/user-details" element={<UserDetails/>} /> 
        <Route path="/test" element={<Loader/>} /> 
      </Routes>
    </Router>

    </SnackbarProvider>
  );
}

export default App;
