import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import doctoravtar from "../../assets/images/Dentist-avatar.png";
import axios from 'axios';
import "./UserDetails.css";

const UserDetails = () => {
  const [userData, setUserData] = useState({}); // Initialize as null to handle loading
// console.log(userData.data.data.firstName);

  useEffect(() => {
    const getuserdetails = async () => {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/auth/v1/user`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserData(response.data.data); // Set the user data correctly
        console.log(userData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getuserdetails(); // Call the async function
  }, []);

  // Check if userData exists, otherwise show "Loading..."
  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="user-details-primary-container">
      <Navbar />
      <div className="user-details-wrapper">
        <div className="user-details">
          <div className="avatar-image-container">
            <img src={doctoravtar} alt="User Avatar" />
          </div>
          <div className="user-details-page-data">
            <p><strong>UserID:</strong> {userData._id}</p>
            <p><strong>Full Name:</strong> {userData.fullName}</p>
            <p><strong>Email-Id:</strong> {userData.email}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
