import React, { useEffect, useState } from 'react';
import { MdOutlineTimer } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { ShimmerPostDetails } from 'react-shimmer-effects'; // Importing shimmer
import "./ProductDescriptionPage.css";

const ProductDescriptionPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://cd.rapydlaunch.com/auth/v1/product/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`
          }
        });
        setProduct(response.data.ProductItem);
        setSuggestions(response.data.ProductItem.suggestions);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  if (loading) {
   
    return (
      <div className="shimmer-loading">
        <ShimmerPostDetails card imageHeight={300} caption />
      </div>
    );
  }

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  if (!product) {
    return <div className="error-message">No product found</div>;
  }

  return (
    <div className="description-container">
      <div className="breadcrumb">
        <a href="/">Home</a>
        <span><IoIosArrowForward /></span>
        <a href="/product-description">{product.name}</a>
      </div>

      <div className="product-details">
        <div className="product-images">
          {product.images && product.images.length > 0 ? (
            product.images.map((image, index) => (
              <Zoom key={index}>
                <img src={image} alt={`Product Image ${index}`} className="product-image" />
              </Zoom>
            ))
          ) : (
            <p>No images available</p>
          )}
        </div>

        <div className="product-info">
          <h1 className="product-title">{product.name}</h1>
        </div>
      </div>

      <div className="suggestions-section">
        <h3>Related Products</h3>
        <div className="suggestions-container">
          {suggestions.length > 0 ? (
            suggestions.map((item, index) => (
              <div key={index} className="suggestion-item">
                <h4>{item.name}</h4>
                {item.images && item.images.length > 0 ? (
                  item.images.map((image, imgIndex) => (
                    <Zoom key={imgIndex}>
                      <img src={image} alt={`Suggestion ${imgIndex}`} className="suggestion-image" />
                    </Zoom>
                  ))
                ) : (
                  <p>No images available</p>
                )}
              </div>
            ))
          ) : (
            <p>No suggestions available</p>
          )}
        </div>
      </div>

      <div className="suggestions-section">
        <h3>C-Chart for Additional Options</h3>
        <div className="suggestions-container">
          {product.notAvail && product.notAvail.length > 0 ? (
            product.notAvail.map((pdfLink, index) => (
              <div key={index} className="pdf-link">
                <a href={product.pdfLink} target="_blank" rel="noopener noreferrer" className="pdf-thumbnail">
                  <span>{pdfLink} - Mould chart</span>
                </a>
              </div>
            ))
          ) : (
            <p>No additional links available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDescriptionPage;
