import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import FilterComponent from "../../components/FilterComponent/FilterComponent";
import ResultCards from "../../components/Result-Cards/ResultCards";
import { ShimmerContentBlock } from "react-shimmer-effects";
import "./ResultsPage.css";
import "../../assets/css/mobile.css";
import axios from "axios";

const ResultsPage = () => {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("userToken");

  const [selectedFilters, setSelectedFilters] = useState({
    shape: [],
    color: [],
    vendor: [],
    teethCase: [],
    width: "",
    height: "",
    mass: "",
  });

  // Load filters from URL query parameters
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const width = queryParams.get("width") || "";
    const mass = queryParams.get("mass") || "";
    const height = queryParams.get("height") || "";
    const color = queryParams.get("color") ? JSON.parse(queryParams.get("color")) : [];
    const shape = queryParams.get("shape") ? JSON.parse(queryParams.get("shape")) : [];
    const vendor = queryParams.get("vendor") ? JSON.parse(queryParams.get("vendor")) : [];
    const teethCase = queryParams.get("teethCase") ? JSON.parse(queryParams.get("teethCase")) : [];

    setSelectedFilters({
      shape,
      color,
      vendor,
      teethCase,
      width,
      height,
      mass,
    });
  }, [location.search]);

  // Fetch data based on selected filters
  const fetchData = async () => {
    if (token) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/auth/v1/products`,
          {
            width: selectedFilters.width,
            height: selectedFilters.height,
            color: selectedFilters.color,
            shape: selectedFilters.shape,
            vendor: selectedFilters.vendor,
            teethCase: selectedFilters.teethCase,
            mass: selectedFilters.mass,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setProducts(response.data.Products);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Trigger data fetch whenever selectedFilters change
  useEffect(() => {
    // Check if there are valid filters to fetch data
    if (
      selectedFilters.width ||
      selectedFilters.height ||
      selectedFilters.color.length ||
      selectedFilters.shape.length ||
      selectedFilters.vendor.length ||
      selectedFilters.teethCase.length ||
      selectedFilters.mass
    ) {
      fetchData();
    }
  }, [selectedFilters, token]);

  return (
    <div className="Results-Page-Wrapper">
      <div className="text-container">
        <span className="text-container-inner">
          <p>All Items</p>
          <p>Discover top searches here</p>
        </span>
      </div>

      <div className="results-wrapper">
        <FilterComponent
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          mass={selectedFilters.mass}
          width={selectedFilters.width}
          height={selectedFilters.height}
        />

        <div className="results-section">
          <h3>
            Showing results 1-{products.length} of {products.length} results
          </h3>
          <div className="results-cards">
            {isLoading ? (
              <div className="shimmer-loader">
                <ShimmerContentBlock
                  title
                  text
                  cta
                  thumbnailWidth={370}
                  thumbnailHeight={370}
                />
              </div>
            ) : products.length > 0 ? (
              products.map((product) => (
                <ResultCards
                  key={product._id}
                  _id={product._id}
                  mass={product.mass}
                  vendor={product.vendor}
                  title={product.name}
                  description={product.description}
                  color={product.color}
                  height={product.height}
                  width={product.width}
                  shape={product.shape}
                  casetype={product.case}
                  images={product.images}
                  createdDate={product.createdDate}
                />
              ))
            ) : (
              <div className="notfound">
                <img src="https://collings-denture.s3.ap-southeast-2.amazonaws.com/collings-denture/1729631033200_image_1.png" alt="No data" />
                <p>No data found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultsPage;
