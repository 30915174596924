import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import MiddleSection from "../../components/MiddleSection/MiddleSection";

const Home = () => {
  const [filters, setFilters] = useState({});
  const navigate = useNavigate(); // Hook to programmatically navigate


  return (
    <div>
      <MiddleSection />
    </div>
  );
};

export default Home;
