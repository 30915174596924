import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Input, Button, message } from "antd"; // Import necessary components
import "./Login.css";
import Logo from '../../assets/images/logo.png'

const Login = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpRequested, setOtpRequested] = useState(false); 
  const navigate = useNavigate();

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/v1/sendotp`,
        { email },
        { headers: { "Content-Type": "application/json" } }
      );

      const data = response.data;
      if (data.success) {
        setOtpRequested(true);
        message.success("OTP sent successfully");
      } else {
        navigate("/signup", { state: { email } });
      }
    } catch (error) {
      navigate("/signup", { state: { email } });
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const data = JSON.stringify({
        email,
        otp,
      });
      const otpResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/v1/verifyotp`,
        data,
        { headers: { "Content-Type": "application/json" } }
      );

      const otpData = otpResponse.data;
      if (otpData.status) {
        localStorage.setItem("userToken", otpData.userToken);
        navigate("/home");
      } else {
        message.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page">
      <div className="container center">
        <div className="left-container">
          <div className="logo-login">
            <img src={Logo} alt="collings-denture"/>
          </div>
          <h2>Signin with Email</h2>
          <p>Find the perfect tooth, fast and precise.</p>
          <form onSubmit={otpRequested ? handleVerifyOtp : handleSendOtp}>
            <div className="input-group">
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={otpRequested} // Disable email input after OTP is sent
                placeholder="Enter your email"
              />
            </div>

            

            {otpRequested && (
              <>
                <div className="input-group">
                  <Input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                    placeholder="Enter 6 digit otp."
                  />
                </div>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {loading ? "Verifying..." : "Verify OTP"}
                </Button>
              </>
            )}

            {!otpRequested && (
              <Button type="primary" htmlType="submit" loading={loading}>
                {loading ? "Sending OTP..." : "Get Started"}
              </Button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
